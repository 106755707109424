<template>
  <div class="checkbox-field">
    <label class="checkbox-title"> {{ lang[activeLang][input.title] }} </label>
    <div
      v-for="(option, index) in input.options"
      :key="index"
      class="checkbox-container"
    > 
      <input
        :id="inputKey"
        v-model="checkedValues"
        :value="option"
        class="input-checkbox"
        type="checkbox"
        @change="inputUpdate(input)"
      >
      <label
        :for="option"
        class="checkbox-label"
      >{{ (lang[activeLang][option] && lang[activeLang][option]) || option }}<em v-if="input.required">*</em></label>
    </div>
    <label
      v-if="input.hasError && input.requiredError"
      class="error-text"
    >
      {{ lang[activeLang]['This field is required.'] }}</label>
    <label
      v-else-if="input.hasError && input.errorMsg"
      class="error-text"
    >{{
      lang[activeLang][input.errorMsg]
    }}</label>
  </div>
</template>

<script>
export default {
  name: 'FormCheckbox',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      checkedValues: [],
    }
  },
  created() {
    // Update current message from formData which gets updated from localStorage.
    this.checkedValues = this.formData[this.input.index] || []
  },
  methods: {
    inputUpdate: function(input) {
      // Send the new value up to the parent.
      this.$emit('formUpdate', this.checkedValues)

      // If there's no error, don't perform a valid check to see if it has updated.
      if (!input.hasError) return

      // If the length increases, then it's no longer empty and we'll remove the requiredError.
      if (this.checkedValues.length > 0) {
        input.requiredError = false
      }

      // If it had a valid error we'll validate one more time.
      if (input.errorMsg && input.isValid && input.isValid(this.checkedValues)) {
        input.errorMsg = null
      }

      // If there's no validation or required error we remove the hasError property.
      if (!input.errorMsg || input.requiredError) {
        input.hasError = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkbox-field {
  width: 100%;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px;
  border-style: solid;
  border-color: #d4d6de;
  padding: 18px 1em;
  flex-wrap: nowrap;
}

.checkbox-title {
  text-align: left;
  display: block;
  color: rgb(102, 102, 102);
}

.checkbox-container {
  padding-top: .5em;
  width: 100%;
  display: flex;
}

.checkbox-label {
  margin-left: .5em;
  text-align: left;
  color: rgb(102, 102, 102);
}

@media screen and (min-width: 900px) {
  .button-field {
    width: 45%;
  }
}

a {
  text-decoration: none;
}

.form-button {
  background-color: #db4e54;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.form-button-text {
  pointer-events: none;
}
</style>
