<template>
  <div class="form-field">
    <div class="input-container">
      <DatePicker
        v-model="date"
        timezone="UTC"
      >
        <template #default="{ inputValue, togglePopover }">
          <input
            :value="inputValue"
            class="floating__input"
            readonly
            @click="togglePopover()"
          >
            
          <label
            class="floating__label"
            :data-content="input.title"
          >{{ lang[activeLang][input.title] || input.title
          }}<em v-if="input.required">*</em></label>
        </template>
      </DatePicker>
    </div>
    <label
      v-if="input.hasError && input.requiredError"
      class="error-text"
    >
      {{ lang[activeLang]['This field is required.'] }}</label>
    <label
      v-else-if="input.hasError && input.errorMsg"
      class="error-text"
    >{{
      lang[activeLang][input.errorMsg]
    }}</label>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';

export default {
  name: 'FormDatePicker',
  components: {
    DatePicker,
  },
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'EN'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      date: new Date(),
    };
  },
  watch: {
    date: function(newDate) {
      this.inputUpdated(this.input, newDate)
    }
  },
  created() {
    // If there's no stored data skip updating picked.
    if (this.formData[this.input.index]) {
      // console.log(this.formData[this.input.index])
      this.date = new Date(this.formData[this.input.index])
      // this.date = new Date(parseInt(this.formData[this.input.index]) * 1000)
      // console.log(this.date)
    }

    this.inputUpdated(this.input, this.date)
  },
  methods: {
    inputUpdated(input, newVal) {
      // Send new value up to the parent
      // newVal = Math.floor(newVal.getTime() / 1000)
      // console.log(newVal)
      // console.log("ACTUAL DATE: ", new Date(newVal * 1000))
      this.$emit('formUpdate', newVal)
      // If there's no error then return
      if (!input.hasError) return

      // If there's now a value then we're going to disable the required error.
      if (newVal.length > 0) {
        input.requiredError = false
      }

      // Perform a valid check to see if the input is now valid.
      if (input.errorMsg && input.isValid && input.isValid(newVal)) {
        input.errorMsg = null
      }

      // If there's no error message or requiredError then we're going to remove the error flag entirely.
      if (!input.errorMsg || input.requiredError) {
        input.hasError = false
      }
    }
  }
}
</script>

<style scoped>
.v3dp__datepicker:has(.floating__input:-webkit-autofill) + .floating__label,
.v3dp__datepicker:has(.floating__input:-webkit-autofill:hover)
  + .floating__label,
.v3dp__datepicker:has(.floating__input:-webkit-autofill:focus)
  + .floating__label,
.v3dp__datepicker:has(.floating__input:not(:placeholder-shown))
  + .floating__label,
.v3dp__datepicker:has(.floating__input:focus) + .floating__label {
  color: rgb(88, 147, 214);
  font-size: 0.88em;
  top: -0.5em;
  transform: translateY(0%);
  margin-top: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60.5%,
    transparent 61%,
    transparent 100%
  );
}
</style>
