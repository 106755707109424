<template>
  <div
    class="form-field"
    :class="{ fullwidth: input.fullwidth }"
  >
    <div
      class="select-container"
      :class="{hasError: input.hasError }"
    >
      <select
        :id="inputKey"
        v-model="currentVal"
        required
        :class="{ selected: selectedIndex >= 0}"
        @change="inputUpdate(input, $event.target.selectedIndex)"
      >
        <option disabled>
          {{ lang[activeLang]['Select'] }}
        </option>
        <option
          v-for="(option, index) in input.options"
          :key="index"
          :selected="formData[inputKey] === option"
          :default="formData[inputKey] === option"
          :value="option"
        >
          {{ (lang[activeLang][option] && lang[activeLang][option]) || option }}
        </option>
      </select>
      <label
        class="floating__label"
        :for="inputKey"
        :data-content="input.title"
      >{{ lang[activeLang][input.title] || input.title
      }}<em v-if="input.required">*</em></label>
      <img
        class="select-arrow"
        src="@/assets/arrow.svg"
      >
    </div>
    <label
      v-if="input.hasError && input.requiredError"
      class="error-text"
    >
      {{ lang[activeLang]['This field is required.'] }}</label>
    <label
      v-else-if="input.hasError && input.errorMsg"
      class="error-text"
    >{{
      lang[activeLang][input.errorMsg]
    }}</label>
  </div>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentVal: this.formData[this.inputKey] || this.input.title,
      selectedTitle: '',
      selectedIndex: -1
    }
  },
  created() {
    let targetTitle = this.formData[this.input.index] || this.input.title
    // We stored a string value for the selected option so we need to loop to find the associated index.
    for (let i = 0; i < this.input.options.length; i++) {
      let optionVal = this.input.options[i]
      if (optionVal == targetTitle) {
        // Add one to the selected index to account the placeholder 'Select' option.
        this.selectedIndex = i + 1
      }
    }
    //Update select title to the current language selection.
    this.selectedTitle = this.lang[this.activeLang][targetTitle]
  },
  methods: {
    inputUpdate: function(input, newIndex) {
      this.selectedIndex = newIndex
      // Send the new value up to the parent. Subtract one to count for placehoder 'Select' option.
      this.$emit('formUpdate', this.input.options[newIndex - 1])

      // If there's no error, don't perform a valid check to see if it has updated.
      if (!input.hasError) return

      // If the select is greater than -1 that means a value has been selected, for default is -1.
      if (this.selectedIndex > -1) {
        input.requiredError = false
      }

      // If it had a valid error we'll validate one more time.
      if (
        input.errorMsg &&
        input.isValid &&
        input.isValid(this.input.options[newIndex - 1])
      ) {
        input.errorMsg = null
      }

      // If there's no validation or required error we remove the hasError property.
      if (!input.errorMsg || input.requiredError) {
        input.hasError = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  background-color: #fff;
  border-radius: 5px;
  padding: 18px 0;
  border: none;
  width: 100%;
  color: rgb(102, 102, 102);
  font-size: 1em;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1em;
}

.select-container {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #d4d6de;
  position: relative;
  display: flex;
  font-size: 1rem;
  align-items: center;
}

.select-arrow {
  width: 12px;
  height: 12px;
  color: #d4d6de;
  pointer-events: none;
  margin-right: 1em;
}

.floating__label {
  margin-right: calc(1.5em + 12px);
}

@media screen and (min-width: 900px) {
  select {
    padding: 14px 0;
    
  }
}

@-moz-document url-prefix() {
  select {
    text-indent: 0.35em;
  }
}

select:-webkit-autofill,
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus {
  border: 1px solid #d4d6de;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

select:-webkit-autofill + .floating__label,
select:-webkit-autofill:hover + .floating__label,
select:-webkit-autofill:focus + .floating__label,
select.selected + .floating__label,
select:focus + .floating__label {
  color: rgb(88, 147, 214);
  font-size: 0.84em;
  top: -0.5em;
  transform: translateY(0%);
  margin-top: 0;
  background: #fff;
  margin-right: 0;
}


select:focus {
  border-color: #9cbaf2;
}

.hasError {
  border-color: rgb(214, 88, 88);
}
</style>
