const english = {
  //Header Home
  ['To request legal assistance online']:
    'Please be sure to read about', 
  ['before filling out this form']: 'before filling out this form.',
  ['Please note that we cannot respond immediately']: 'Please note that we cannot respond immediately. Evaluation of complaints takes time, and because we have limited resources, we often cannot make these evaluations quickly.',
  ['All fields are required']: 'All fields are required',
  ['in order for us to process your request for assistance']: 'in order for us to process your request for assistance.',

  //Header File Form
  ['PLEASE BE ADVISED:']: 'PLEASE BE ADVISED:',
  ["The purpose of this intake form is for the ACLU of San Diego ('ACLU-SDIC') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-NJ. The ACLU-NJ does not represent you unless you and the ACLU-NJ have both signed a written retainer agreement."]:
    "The purpose of this intake form is for the ACLU of San Diego ('ACLU-SDIC') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-NJ. The ACLU-NJ does not represent you unless you and the ACLU-NJ have both signed a written retainer agreement.",
  ['You are solely responsible']: 'You are solely responsible',
  ['for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.']:
    'for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.',
  ['Do not wait to hear from the ACLU-NJ before proceeding with your matter.']:
    'Do not wait to hear from the ACLU-NJ before proceeding with your matter.',
  ['Please be sure to read']: 'Please be sure to read',
  ['before filling out this form.']: 'before filling out this form.',
  ['There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.']:
    'There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.',
  ['San Diego Bar Lawyer Referral Service']:
    'San Diego Bar Lawyer Referral Service',
  ['San Diego Rio Grande Legal Aid']: 'San Diego Rio Grande Legal Aid',
  ['San Diego Access to Justice Commission']:
    'San Diego Access to Justice Commission',
  ['San Diego Law Help']: 'San Diego Law Help',
  ['(click on “find a lawyer”) or call 1-877-9TEXBAR (toll free)']:
    '(click on “find a lawyer”) or call 1-877-9TEXBAR (toll free)',
  ['or call 1-888-988-9996 (toll free)']: 'or call 1-888-988-9996 (toll free)',
  ['(click on “find legal assistance”) or call 800-204-2222 ext. 2155']:
    '(click on “find legal assistance”) or call 800-204-2222 ext. 2155',
  ['If you would like to request legal assistance, fill out the form below.']:
    'If you would like to request legal assistance, fill out the form below.',
  ['If you are reaching out about an issue at your California K-12 public school']:
    'If you are reaching out about an issue at your California K-12 public school',
  ['To contact us about a legal concern']:
    'To contact us about a legal concern related to accurate and inclusive education, such as your school board seeking to ban inclusive curricular materials or your school adopting policies harmful to LGBTQ+ students, please email us with your name, contact information, school district, and description of the issue at ', 

  // Header Optional Survey
  ['OPTIONAL SURVEY']: "OPTIONAL SURVEY",

  // Header Form Complete
  ['WE HAVE RECEIVED YOUR COMPLAINT']: 'WE HAVE RECEIVED YOUR COMPLAINT',
  ['Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.']:
    'Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.',
  ['Return to ACLU New Jeresey']: 'Return to ACLU New Jeresey',
  ['THANK YOU FOR SUBMITTING YOUR COMPLAINT']: 'THANK YOU FOR SUBMITTING YOUR COMPLAINT',
  ['We appreciate the information.']: 'We appreciate the information.',
  //Misc
  ['Language']: 'Language',
  ['Submit Request']: 'Submit Complaint',
  ['Submit Survey']: 'Submit Survey',
  ['Visit ACLU affiliates']: 'Visit ACLU affiliates',
  ['Indicates the required fields.']: 'Indicates the required fields.',
  ['This field is required.']: 'This field is required.',
  ['Please review the highlighted fields.']:
    'Please review the highlighted fields.',
  ['Select']: 'Select',

  // Titles
  ['ACLU San Diego and Imperial Counties Online Form To Request Legal Assistance']:
    'ACLU San Diego and Imperial Counties Online Form To Request Legal Assistance',
  ['ACLU OF SOUTHERN CALIFORNIA ONLINE FORM TO REQUEST LEGAL ASSISTANCE']:
    'SECURELY SUBMIT YOUR LEGAL ISSUE TO THE ACLU',
  ['THE LOCATION OF THIS INCIDENT']: 'THE LOCATION OF THIS INCIDENT',
  ['REQUEST FOR LEGAL ASSISTANCE']: 'REQUEST FOR LEGAL ASSISTANCE',
  ['CONTACT INFORMATION']: 'CONTACT INFORMATION',
  ['REQUEST LEGAL ASSISTANCE']:
    'REQUEST LEGAL ASSISTANCE',
  ['MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)']:
    'MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)',
  ['LAWSUIT INFORMATION (OPTIONAL)']: 'LAWSUIT INFORMATION (OPTIONAL)',
  ['DETAILS OF COMPLAINT']: 'DETAILS OF COMPLAINT',
  ['DISCLAIMER AND NOTICE']: 'DISCLAIMER AND NOTICE',
  ['ATTORNEY INFORMATION (OPTIONAL)']: 'ATTORNEY INFORMATION (OPTIONAL)',
  ['AGREEMENT']: 'AGREEMENT',

  // Incident Location
  ['Did this incident happen in San Diego?']:
    'Did this incident happen in San Diego or Imperial Counties?',
  ['Yes']: 'Yes',

  // Contact and Filer Information
  ['Are you filing for yourself or on behalf of someone else?']:
    'Are you filing for yourself or on behalf of someone else?',
  ['Myself']: 'Myself',
  ['Someone else']: 'Someone else',
  ['The relationship between you and the affected individual']:
    'The relationship between you and the affected individual',
  ['First Name']: 'First Name',
  ["Affected Individual's First Name"]: "Affected Individual's First Name",
  ["Affected Individual's Last Name"]: "Affected Individual's Last Name",
  ['Last Name']: 'Last Name',
  ['Address line']: 'Address line',
  ['Address line 2']: 'Address line 2',
  ['State']: 'State',
  ['City']: 'City',
  ['Zip Code']: 'Zip Code',
  ['Phone Number']: 'Phone Number',
  ['Email']: 'Email',
  ['Title']: 'Title',
  ['If a prisoner, prison name?']: 'If a prisoner, prison name?',
  ['Prisoner Number']: 'Prisoner Number',
  ['Ethnic Racial Background']: 'Ethnic/Racial Background',

  // Complaint Against
  ['Agency']: 'Agency',
  ['May we contact this person or agency?']:
    'May we contact this person or agency?',

  // ATTORNEY INFORMATION (OPTIONAL)
  ['Are you represented by a lawyer in this matter?']:
    'Are you represented by a lawyer in this matter?',

  // Reported
  ['Have you reported this incident to any other agency?']:
    'Have you reported this incident to any other agency?',
  ['If yes, please list all the agency names.']:
    'If yes, please list all the agency names.',

  // Incident Information
  ['Date of Incident']: 'Date of Incident',
  ['County of Occurrence']: 'County of Occurrence',
  ['Please describe IN DETAIL, all the events that led you to file this complaint.']:
    'Please describe IN DETAIL, all the events that led you to file this complaint.',
  ['State clearly what you would like the ACLU of San Diego to do for you.']:
    'State clearly what you would like the ACLU of San Diego to do for you.',

  // Field Errors
  ['Please enter a valid email address.']:
    'Please enter a valid email address.',
  ['Please enter a valid phone number.']: 'Please enter a valid phone number.',
  ['Please enter a valid zip code.']: 'Please enter a valid zip code.',

  // Notices
  ['by_checking_this_box']:
    'I want to file this complaint anonymously.',
  ['location_notice']:
    'Please tell us more information about the location of this incident. ACLU SoCal only accepts cases that originate in Southern California.',
    ['optional_survey']:
    'The information requested below is optional.',
  ['affiliate_notice']:
    'If this incident did not arise in Southern California, we cannot help you at this time. Please click the button below to redirect you to the correct ACLU affiliate.',
  ['contact_info_notice']:
    'Contact Information of the person affected by the incident',
  ['background_notice']:
    'The following Ethnic/Racial Background information is optional. This is used for internal purposes only to provide the ACLU of San Diego demographic information about the communities it serves throughout San Diego.',
  ['complaint_against_notice']:
    'Please enter information about the individual, agency, and/or organization your complaint is against.',
  ['filer_contact_info_notice']:
    'Contact Information of the person who is filing on behalf of the affected individual',
  ['incident_notice']:
    'Briefly describe the incident that led you to file a complaint with our office. Please include dates, places, and names of those directly involved. Note in your summary whether you have supporting documentation and we will reach out to you separately to obtain copies. If you have a link to any relevant video, please include the URL in the summary.',
  ['witness_notice']:
    'If you have a witness to support your claims, please enter their information here.',
  ['attorney_notice']:
    'If you are represented by an attorney on this matter, please provide the information here.',
  ['lawsuit_notice']:
    'If a criminal or civil lawsuit has been filed against you or on your behalf regarding your complaint, please provide the information here.',
  ['disclaimer_notice1']:
    'This Request does not give legal advice, and you should not rely on it as legal advice. You should not rely on the information you get from this site and should speak with a lawyer to get advice on your specific situation. We also cannot promise that the information on this site is complete, accurate, or up-to-date.',
  ['disclaimer_notice2']:
    'This Request is not a solicitation or an offer by the American Civil Liberties Union and its affiliates to represent you. We cannot promise you that the information you provide will lead to any specific action on the American Civil Liberties Union or its affiliates part. Once you complete the Request, the American Civil Liberties Union of San Diego may not do anything—including contact you—about your situation.',
  ['disclaimer_notice3']:
    'If you fill out this Request, you agree that the American Civil Liberties Union, the national ACLU or its affiliates, if identified as part of the Request, or one of its coalition partners may use the information you give us, as long as we don’t include your name, address, email or phone number, for one or more of the following purposes: (1) legislative testimony, (2) litigation; (3) contacting a city, state or federal agency; or (4) telling your story to the public, including the media. If the American Civil Liberties Union, the national ACLU or its affiliates, or one of its coalition partners wants to identify you, we will contact you prior to doing so.',
  ['disclaimer_notice4']:
    'We will keep your name, address, telephone number and email confidential unless you give us permission to use it or unless we are ordered to turn it over by a court (although we will attempt to prevent any disclosure).',
  ['agreement_notice1']:
    'By clicking the "Submit Request" button to indicate that the information you have provided is true and correct; that you understand that, by accepting this complaint form, the ACLU of San Diego is not agreeing to represent you; and that you understand that the ACLU of San Diego is not responsible for ensuring that any statute of limitations requirement or other deadline is met in your case.',
  ['agreement_notice2']:
    'If you submit a complaint, you agree that the ACLU of San Diego or the national ACLU may use the information you give us, as long as we don’t include your name, address, email, or phone number. We will keep your name, address, telephone number, and email confidential unless you give us permission to use it or unless we are ordered to turn it over by a court (although we will attempt to prevent any disclosure).',
  ['disclaimer_notice_1']:
  'This Survey does not give legal advice, and you should not rely on it as legal advice. You should not rely on the information you get from this site and should speak with a lawyer to get advice on your specific situation. We also cannot promise that the information on this site is complete, accurate, or up-to-date.',
  ['disclaimer_notice_2']:
  'This Survey is not a solicitation or an offer by the American Civil Liberties Union and its affiliates to represent you. We cannot promise you that the information you provide will lead to any specific action on the American Civil Liberties Union’s or its affiliates’ part. Once you complete the survey, the American Civil Liberties Union of Southern California may not do anything—including contact you—about your situation.',
  ['disclaimer_notice_3']:
  'If you fill out this Survey, you agree that the American Civil Liberties Union, the national ACLU or its affiliates, if identified as part of the Survey, or one of its coalition partners may use the information you give us, as long as we don’t include your name, address, email or phone number, for one or more of the following purposes: (1) legislative testimony, (2) litigation; (3) contacting a city, state or federal agency; or (4) telling your story to the public, including the media. If the American Civil Liberties Union, the national ACLU or its affiliates, or one of its coalition partners wants to identify you, we will contact you prior to doing so.',
  ['disclaimer_notice_4']:
  'We will keep your name, address, telephone number and email confidential unless you give us permission to use it or unless we are ordered to turn it over by a court (although we will attempt to prevent any disclosure).',


// Checkbox options
  ['American Indian']:
    'American Indian / Indigenous or Alaska Native',
  ['Asian']:
    'Asian',
  ['Black']:
    'Black or African American',
  ['Hispanic']:
    'Hispanic or Latino/Latinx',
  ['Arab']:
    'Arab, Middle Eastern, or North African',
  ['South Asian']:
    'South Asian',
  ['Native Hawaiian']:
    'Native Hawaiian or Other Pacific Islander',
  ['White']:
    'White',
  ['Not listed']:
    'Not listed (please specify)'
}

export default english
