<template>
  <fade-in>
    <div
      id="FormCompleteHeader"
      class="header-container"
    >
      <h1 class="white">{{ lang[activeLang]['THANK YOU FOR SUBMITTING YOUR COMPLAINT'] }}</h1>
      <p>
        {{
          lang[activeLang][
            'Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.'
          ]
        }}
      </p>
      <FormButton
        :input="{
          title: 'Return to ACLU SoCal',
          url: 'https://www.aclusocal.org/en'
        }"
        :active-lang="activeLang"
      />
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'
import FormButton from './FormButton.vue'

export default {
  name: 'FormCompleteHeader',
  components: {
    FormButton,
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  },
  mounted() {
    let elmnt = document.getElementById('FormCompleteHeader')
    elmnt.scrollIntoView()
    window.scrollTo(0,0)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.white {
  color:white;
  padding-bottom:40px;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 24px;
}

p {
  text-align: left;
}

em {
  color: rgb(214, 88, 88);
}

</style>

