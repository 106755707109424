<template>
  <div
    class="navigation-container"
  >
    <a href="https://www.aclusocal.org/en">
      <img
        class="navigation-logo"
        src="@/assets/logo.svg"
      >
    </a>
    <div class="lang-container hidden">
      <h3 class="sub-header">
        {{ lang[activeLang]['ACLU of Southern California Online Form To Request Legal Assistance'] }}
      </h3>
      <form-select
        id="lang-select"
        :input="{ title: 'Language', options: ['English', 'Español'] }"
        :active-lang="activeLang"
        @formUpdate="$emit('formUpdate', $event)"
      />
    </div>
  </div>
  <div class="pre-header">
    <img
        class="pre-header-image"
        src="@/assets/marquee-title.png"
      >
  </div>
</template>

<script>
import FormSelect from './FormSelect.vue'

export default {
  name: 'Navigation',
  components: {
    FormSelect,
  },
  props: {
    activeLang: {
      type: String,
      default: "EN",
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navigation-logo {
  width: 200px;
  height: 95px;
  color: black;
}

#lang-select {
  width: 124px;
  margin-bottom: 0em;
  margin-top: 0;
}

.navigation-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 18px;
}

.lang-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.sub-header {
  display: none;
  margin-right: 1em;
  margin-left: 1em;
}

.pre-header {
  background-image: url('~@/assets/issue.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position:right;
  width:100%;
  height:180px;
}

.pre-header-image {
  margin-top:150px;
  margin-left:10%;
  margin-right:10%;
  width:80%;
  height:90px;
}

@media screen and (min-width: 900px) {
  .lang-container {
    flex-direction: row;
    align-items: center;
  }
  .sub-header{
    display: block;
  }
  .spacer {
    display: block;
  }
  
}
@media screen and (max-width: 700px) {
  .pre-header-image {
    margin-top:150px;
    margin-left:0;
    margin-right:0;
    width:100%;
    height:110px;
  }
}

h1{
  font-size: 24px;
}

h3 {
  white-space: nowrap;
  text-align: right;
  flex-grow: 100;
}

.hidden {
  display: none;
}

</style>
